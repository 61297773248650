/* eslint-disable @typescript-eslint/ban-ts-comment */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare type addToast = (
  subject: string,
  message: string,
  autoClose?: boolean,
  status?: 'success' | 'error',
) => void

class ToastContainer extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    const toastContainerClass =
      'fixed top-10 right-6 flex flex-col gap-4 z-1000'.split(' ')
    this.classList.add(...toastContainerClass)
    this.id = 'toast-container'

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.addedNodes.length) {
          const lastChild = mutation.addedNodes[
            mutation.addedNodes.length - 1
          ] as HTMLElement

          if (lastChild.getAttribute('auto-close') === 'true') {
            setTimeout(() => {
              // @ts-ignore
              lastChild.remove()
            }, 6000)
          }
        }
      })
    })

    observer.observe(this, { childList: true })
  }
}

class Toast extends HTMLElement {
  constructor() {
    super()
  }

  static get observedAttributes() {
    return ['subject', 'message', 'auto-close', 'status']
  }

  // @ts-ignore
  attributeChangedCallback(property, oldValue, newValue) {
    if (oldValue === newValue) return
    // @ts-ignore
    this[property] = newValue
  }

  connectedCallback() {
    const toastClass =
      'bg-white rounded-lg p-4 shadow-md flex flex-row gap-4 transition-all delay-300 ease-in-out w-96 animate-enter-right items-start max-w-[calc(100vw_-_48px)]'.split(
        ' ',
      )
    this.classList.add(...toastClass)

    const icon =
      this.getAttribute('status') === 'success' ? '/success.svg' : '/danger.svg'

    this.innerHTML = `
      <img src="${icon}" alt="status icon" class="w-10 h-10 -ml-1 -mt-1" />
      <div class="flex flex-col gap-1 flex-1">
        <span class="font-bold text-base text-neutral-900" id="toast-subject">
          ${this.getAttribute('subject')}
        </span>
        <p id="toast-message" class="text-sm text-gray-600">
          ${this.getAttribute('message')}
        </p>
      </div>
      <button id="toast-close" class="ml-auto"><img src="/close.svg" alt="close" class="w-9 h-9 -mt-2 -mr-2" /></button>
    `

    this.querySelector<HTMLButtonElement>('#toast-close')?.addEventListener(
      'click',
      () => {
        this.remove()
      },
    )

    if (this.getAttribute('auto-close') === 'true') {
      setTimeout(() => {
        this.classList.add('translate-x-104')
      }, 5000)
    }
  }
}

customElements.define('toast-container', ToastContainer)
customElements.define('toast-element', Toast)

const container = document.createElement('toast-container')
container.setAttribute('id', 'toast-container')

document.addEventListener('astro:page-load', () => {
  document.body.appendChild(container)
})

/**
 * Add a toast to the page
 * @param {string} subject
 * @param {string} message
 * @param {boolean} autoClose
 */
function addToast(
  subject: string = '',
  message: string = '',
  autoClose: boolean = true,
  status: 'success' | 'error' = 'error',
) {
  if (typeof window === 'undefined') return

  const toast = document.createElement('toast-element')
  toast.setAttribute('subject', subject)
  toast.setAttribute('message', message)
  toast.setAttribute('auto-close', String(autoClose))
  toast.setAttribute('status', status)
  document.getElementById('toast-container')?.prepend(toast)
}

// @ts-ignore
globalThis.addToast = addToast
